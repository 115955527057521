<template>
  <div class="liquidity-management">
    <base-header type="secondary" class="pb-6 pb-8 pt-2 pt-md-3">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row pb-3">
        <div class="col-12">
          <card header-classes="bg_transparent" body-classes="d-flex" v-show="!newEvent && !editEvent">
            <div class="container">
              <div class="row">
                <div class="col text-right">
                  <base-button class="text-right"
                    type="default-button"
                    size="md"
                    @click="addNewEvent">
                    {{ $t('liquidityManagement.newEvent') }}
                  </base-button>
                </div>
              </div>             
              <b-tabs v-model="tabIndex">
                <b-tab :title="$t('liquidityManagement.liquidityPool')" active>
                  <div class="container">
                    <div class="row pt-2">
                      <div class="col-2">
                        <base-button class="text-right"
                        type="default-button"
                        size="md"
                        @click="paySelected">
                        {{ $t('liquidityManagement.pool.paySelected') }}
                      </base-button>
                      </div>
                      <div class="col-2">
                        {{ $t('liquidityManagement.pool.AHTPending', { amount: AHTPending }) }}
                      </div>
                      <div class="col-2">
                        {{ $t('liquidityManagement.pool.AHTSend', { amount: AHTToSend }) }}
                      </div>
                      <div class="col-2">
                        {{ $t('liquidityManagement.pool.AHTAvailable', { amount: multiTransferBalance }) }}
                      </div>
                      <div class="col-4">
                        <div class="row">
                          <div class="col-8">
                            <base-input v-model="filterPool"></base-input>
                          </div>
                          <div class="col-4">
                            <base-button
                              type="default-button"
                              @click="filterLiquidityPool">
                              {{ $t('liquidityManagement.search') }}
                            </base-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <b-table
                      :fields="liquidityPoolFields"
                      :items="liquidityPoolFiltered"
                      class="liquidity-table"
                      outlined>
                      <template #head(address)="data">
                        <div class="row form-inline">
                          <div class="col-1 p-0">
                            <base-checkbox v-model="selectAll">
                            </base-checkbox>
                          </div>
                          <div class="col-11 p-0 text-center">
                            {{ data.label}}
                          </div>
                        </div>
                      </template>
                      <template  #head(totalHours)="data">
                        <div class="row">
                          <div class="col-12 pb-1">
                            {{ data.label}}
                          </div>
                        </div>
                      </template>
                      <template  #head(AHTReward)="data">
                        <div class="row">
                          <div class="col-12 pb-1">
                            {{ data.label}}
                          </div>
                        </div>
                      </template>
                      <template  #head(payment)="data">
                        <div class="row">
                          <div class="col-12 pb-1">
                            {{ data.label}}
                          </div>
                        </div>
                      </template>
                      <template #cell(address)="data">
                        <div class="row form-inline">
                          <div class="col-1 p-0">
                            <base-checkbox v-show="!data.item.payment" v-model="data.item.selected">
                            </base-checkbox>
                          </div>
                          <div class="col-11 p-0 text-center">
                            {{ data.value}}
                          </div>
                        </div>
                      </template>
                      <template #cell(totalHours)="data">
                        {{ data.value }} hours
                      </template>
                      <template #cell(AHTReward)="data">
                        {{ data.value }} AHT
                      </template>
                      <template #cell(payment)="data">
                        <label v-if="data.value">{{ $t('liquidityManagement.pool.paid') }}</label>
                        <label v-else>{{ $t('liquidityManagement.pool.pending') }}</label>
                      </template>
                    </b-table>
                    <div slot="footer" class="align-items-right pt-2">
                      <b-pagination class="no-border"
                        v-model="currentPoolPage"
                        :total-rows="liquidityPool.length"
                        :per-page="poolPerPage"
                        align="right"
                        style="padding: 0"
                        hide-goto-end-buttons>
                      </b-pagination>
                    </div>
                  </div>
                </b-tab>
                <b-tab :title="$t('liquidityManagement.liquidityEvents')">
                  <div class="container">
                    <div class="row pt-2">
                      <div class="offset-8 col-4">
                        <div class="row">
                          <div class="col-8">
                            <base-input v-model="filterEvents"></base-input>
                          </div>
                          <div class="col-4">
                            <base-button
                              type="default-button"
                              @click="filterLiquidityEvents">
                              {{ $t('liquidityManagement.search') }}
                            </base-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <b-table
                      :fields="liquidityEventsFields"
                      :items="liquidityEventsFiltered"
                      class="liquidity-table"
                      outlined>
                      <template #cell(startDate)="data">
                        {{ dateFormat(data.value) }}
                      </template>
                      <template #cell(endDate)="data">
                        {{ dateFormat(data.value) }}
                      </template>
                      <template #cell(reward)="data">
                        {{ data.value }} AHT
                      </template>
                      <template #cell(status)="data">
                        <label v-if="data.value === 0">{{ $t('liquidityManagement.event.pending') }}</label>
                        <label v-else-if="data.value === 1">{{ $t('liquidityManagement.event.active') }}</label>
                        <label v-else>{{ $t('liquidityManagement.event.finished') }}</label>
                      </template>
                      <template #cell(action)="data">
                        <div class="row">
                          <div class="offset-2 col-3 pl-0 pr-0">
                            <i class="fas fa-pen fa-lg pointer"
                              v-if="data.item.status !== 2"
                              @click="updateEvent(data.item)"></i>
                          </div>
                          <div class="col-3 pl-0 pr-0">
                            <i class="fas fa-trash-alt fa-lg pointer"
                              @click="removeEvent(data.item)"></i>
                          </div>
                        </div>
                      </template>
                    </b-table>
                    <div slot="footer" class="align-items-right pt-2">
                      <b-pagination class="no-border"
                        v-model="currentEventsPage"
                        :total-rows="liquidityEvents.length"
                        :per-page="eventsPerPage"
                        align="right"
                        style="padding: 0"
                        hide-goto-end-buttons>
                      </b-pagination>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </card>
          <card header-classes="bg_transparent" body-classes="d-flex" v-show="newEvent || editEvent">
            <div slot="header" class="row align-items-center">
              <div class="col-md-6">
                <h5 class="h3 mb-0" v-if="editEvent">{{ $t('liquidityManagement.event.edit') }}</h5>
                <h5 class="h3 mb-0" v-show="newEvent">{{ $t('liquidityManagement.event.new') }}</h5>
              </div>
            </div>
            <new-event v-if="newEvent"
              @save-event="saveNewEvent">
            </new-event>
            <edit-event v-if="editEvent"
              :liquidity-event="eventToEdit"
              @edit-event="closeEditEvent">
            </edit-event>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import detectEthereumProvider from '@metamask/detect-provider'
import moment from 'moment'
import { BTabs, BTab, BTable } from 'bootstrap-vue'
import NewEvent from './LiquidityManagement/NewEvent.vue'
import EditEvent from './LiquidityManagement/EditEvent.vue'
const web3v1Factory = require('../lib/web3v1.3.0Helper');

export default {
  data () {
    return {
      tabIndex: 0,
      newEvent: false,
      editEvent: false,
      eventToEdit: null,
      selectAll: false,
      liquidityPoolFields: [
        {
          key: 'address',
          sortable: true,
          label: this.$t('liquidityManagement.pool.address')
        },
        {
          key: 'totalHours',
          sortable: true,
          label: this.$t('liquidityManagement.pool.totalHours')
        },
        {
          key: 'AHTReward',
          sortable: true,
          label: this.$t('liquidityManagement.pool.AHTReward')
        },
        {
          key: 'payment',
          sortable: true,
          label: this.$t('liquidityManagement.pool.status')
        }
      ],
      liquidityEventsFields: [
        {
          key: 'name',
          sortable: true,
          label: this.$t('liquidityManagement.event.name')
        },
        {
          key: 'startDate',
          sortable: true,
          label: this.$t('liquidityManagement.event.startDate')
        },
        {
          key: 'endDate',
          sortable: true,
          label: this.$t('liquidityManagement.event.endDate')
        },
        {
          key: 'reward',
          sortable: true,
          label: this.$t('liquidityManagement.event.reward')
        },
        {
          key: 'status',
          sortable: true,
          label: this.$t('liquidityManagement.event.status')
        },
        {
          key: 'action',
          sortable: false,
          label: this.$t('liquidityManagement.event.action')
        }
      ],
      isConnected: false,
      metamaskAccount: '',
      filterPool: '',
      filterEvents: '',
      currentPoolPage: 1,
      poolPerPage: 20,
      currentEventsPage: 1,
      eventsPerPage: 20
    }
  },
  components: {
    BTabs,
    BTab,
    BTable,
    NewEvent,
    EditEvent
  },
  computed: {
    liquidityEvents () {
      return this.$store.getters.getLiquidityEvents
    },
    liquidityEventsFiltered () {
      return this.$store.getters.getLiquidityEvents.slice((this.currentEventsPage - 1) * this.eventsPerPage, this.currentEventsPage * this.eventsPerPage)
    },
    liquidityPool () {
      return this.$store.getters.getLiquidityPool
    },
    liquidityPoolFiltered () {
      return this.$store.getters.getLiquidityPool.slice((this.currentPoolPage - 1) * this.poolPerPage, this.currentPoolPage * this.poolPerPage)
    },
    multiTransferBalance () {
      return Number(this.$store.getters.getMultiTransferBalance).toFixed(3)
    },
    AHTToSend () {
      let amountToPay = 0

      this.liquidityPool.forEach(user => {
        if(user.selected)
          amountToPay += user.AHTReward
      })

      return amountToPay
    },
    AHTPending () {
      let pendingAmount = 0

      this.liquidityPool.forEach(user => {
        if(!user.payment)
          pendingAmount += user.AHTReward
      })

      return pendingAmount
    },
    isManager () {
      return this.$store.getters.getIsManager
    }
  },
  methods: {
    addNewEvent () {
      this.newEvent = true
    },
    updateEvent (event) {
      this.eventToEdit = event
      this.editEvent = true
    },
    saveNewEvent () {
      this.newEvent = false
      this.tabIndex = 1
    },
    dateFormat (ISODate) {
      let date = moment(ISODate)

      return date.format('MM-DD-YY')
    },
    closeEditEvent () {
      this.editEvent = false
      this.tabIndex = 1
    },
    async removeEvent (event) {
      this.$confirm(
          this.$t('liquidityManagement.deleteConfirmation'),
          '',
          'question')
        .then(async () => {
          await this.$store.dispatch('removeLiquidityEvent', event)
        })
        .catch(() => {})
    },
    changeSelected (status) {
      this.liquidityPool.forEach(user => {
        if(!user.payment) {
          user.selected = status
        }
      })
    },
    getUsersByEvent () {
      let data = []

      this.liquidityPool.forEach(user => {
         if(user.selected) {
           data.push({
             event: user._id,
             address: user.address
           })
         }
      })

      return data
    },
    async paySelected () {
      let web3 = web3v1Factory.getInstance()
      await this.$store.dispatch('isManager', this.metamaskAccount)

      if (this.isManager) {
        let addresses = []
        let tokens = []
        let updateData = await this.getUsersByEvent()
        

        this.liquidityPool.forEach(user => {
          if(user.selected) {
            addresses.push(user.address)
            tokens.push(web3.utils.toWei(String(user.AHTReward), 'ether'))
          }
        })

        let data = {
          address: this.metamaskAccount,
          addresses: addresses,
          tokens: tokens,
          updateData: updateData
        }        
        
        await this.$store.dispatch('sendLiquidityPoolReward', data)

        this.$alert(
          'Reward sended successfully',
          'Success',
          'success')
      } else {
        this.$alert(
          this.$t('liquidityManagement.notManager'),
          'Error',
          'error')
      }
    },
    async connect () {
      const provider = await detectEthereumProvider()

      if (provider) {
          ethereum
            .request({ method: 'eth_requestAccounts' })
            .then(this.handleAccountsChanged)
            .catch((err) => {
              if (err.code === 4001) {
                console.log('Please connect to MetaMask.')
              } else {
                console.error(err)
              }
            })
          } else {
          this.$alert(
            'Metamask is not installed or not running',
            'Error',
            'error')
          this.isConnected = false
      }
    },
    handleAccountsChanged (accounts) {
      if (accounts.length === 0) {
        this.isConnected = false
        console.log('Please connect to MetaMask.')
      } else if (accounts[0] !== this.metamaskAccount) {
        this.metamaskAccount = accounts[0]
        this.isConnected = true
      } else if (accounts[0] === this.metamaskAccount && !this.isConnected) {
        this.isConnected = true
      }
    },
    startApp(provider) {
      if (provider !== window.ethereum) {
        console.error('Do you have multiple wallets installed?')
        return
      }
      
      this.connect()
    },
    async filterLiquidityPool () {
      await this.$store.dispatch('getLiquidityPoolList', this.filterPool)
    },
    async filterLiquidityEvents () {
      await this.$store.dispatch('getLiquidityEventsList', this.filterEvents)
    }
  },
  async mounted() {
    await this.$store.dispatch('getLiquidityEventsList')
    await this.$store.dispatch('getLiquidityPoolList')
    await this.$store.dispatch('getMultiTransferBalancer')

    const provider = await detectEthereumProvider()

    if (provider) {
      ethereum.on('accountsChanged', this.handleAccountsChanged)
      await this.startApp(provider)
    } else {
      this.$alert(
        'Metamask is not installed or not running',
        'Error',
        'error')
      this.isConnected = false
      console.log('Metamask is not installed or not running')
    }

  },
  watch: {
    selectAll (value) {
      this.changeSelected(value)
    }
  },
}
</script>

<style>
  .liquidity-management .nav-item {
    padding-left: 1rem;
    border-bottom: 1px solid #dee2e6;
  }

  .liquidity-management .nav-link {
    color: #b2b3b7;
    background-color: white;
    font-weight: 400;
    font-size: 14px;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: .5rem;
    border-radius: 15px 15px 0 0;
    border: 1px solid #b2b3b7;
    border-bottom: 1px solid white;
  }

  .liquidity-management .nav-link:hover {
    border: 1px solid #b2b3b7;
    border-bottom: 1px solid white;
  }

  .liquidity-management .nav-link.active {
    background-color: #9fdbe7;
    border-bottom: 1px solid #9fdbe7;
    color: white;
    font-weight: 600;
  }

  .liquidity-management .tab-content {
    background-color: white;
    border-radius: 5px;
  }

  .liquidity-management thead {
    background-color: #7a92aa;
    color: white;
  }

  .liquidity-management .pointer {
    cursor: pointer;
  }

  .liquidity-management .liquidity-table tbody tr:hover {
    background: rgba(122, 146, 170, .40)
  }
</style>