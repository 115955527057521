<template>
  <div class="container">
    <div class="row pt-3 pb-4 form-inline">
      <div class="col-md-2 col-lg-1">
        <h5 class="h3 mb-0">{{ $t('liquidityManagement.event.name') }}</h5>
      </div>
      <div class="col-md-6 col-lg-5">
        <base-input maxlength="50"
          inputClasses="w-100"
          v-model="liquidityEvent.name">
        </base-input>
      </div>
      <div class="offset-md-2 col-md-10 offset-lg-1 col-lg-11 error">
        <span v-if="firstAttemp && !$v.liquidityEvent.name.required">{{ $t('liquidityManagement.event.required') }}</span>
        <span v-if="firstAttemp && !$v.liquidityEvent.name.minLength">{{ $t('liquidityManagement.event.minLength') }}</span>
      </div>
    </div>
    <div class="row form-inline">
      <div class="col-md-2 col-lg-1">
        <h5 class="h3 mb-0">{{ $t('liquidityManagement.event.start') }}</h5>
      </div>
      <div class="col-md-2 col-lg-2">
        <flat-pickr class="form-control date w-100"
          v-model="liquidityEvent.startDate"
          placeholder="mm-dd-yyyy"
          :config="config">
        </flat-pickr>
      </div>
      <div class="col-md-2 col-lg-1">
        <h5 class="h3 mb-0">{{ $t('liquidityManagement.event.end') }}</h5>
      </div>
      <div class="col-md-2 col-lg-2">
        <flat-pickr class="form-control date w-100"
          v-model="liquidityEvent.endDate"
          placeholder="mm-dd-yyyy"
          :config="config">
        </flat-pickr>
      </div>
      <div class="col-md-2 col-lg-2">
        <h5 class="h3 mb-0">{{ $t('liquidityManagement.event.rewardAmount') }}</h5>
      </div>
      <div class="col-md-2 col-lg-2 text-right">
        <b-form-input class="w-100"
          maxlength="7"
          v-model="liquidityEvent.reward">
        </b-form-input>
      </div>
    </div>
    <div class="row pt-0 pb-4">
      <div class="offset-md-2 col-md-2 offset-lg-1 col-lg-2 error">
        <span v-if="firstAttemp && !$v.liquidityEvent.startDate.required">{{ $t('liquidityManagement.event.required') }}</span>
      </div>
      <div class="offset-md-2 col-md-2 offset-lg-1 col-lg-2 error">
        <span v-if="firstAttemp && !$v.liquidityEvent.endDate.required">{{ $t('liquidityManagement.event.required') }}</span>
      </div>
      <div class="offset-md-2 col-md-2 offset-lg-1 col-lg-2 error">
        <span v-if="firstAttemp && !$v.liquidityEvent.reward.required">{{ $t('liquidityManagement.event.required') }}</span>
        <span v-if="firstAttemp && !$v.liquidityEvent.reward.maxValue">{{ $t('liquidityManagement.event.amountLimit') }}</span>
      </div>
    </div>
    <div class="row pb-4 form-inline">
      <div class="offset-md-6 col-md-3 offset-lg-4 col-lg-3">
        <base-button class="w-100"
          type="secondary"
          size="md"
          @click="cancel">
          {{ $t('liquidityManagement.event.cancel') }}
        </base-button>
      </div>
      <div class="col-md-3 col-lg-3 text-right">
        <base-button
          class="w-100"
          type="default-button"
          @click="saveEvent"
          size="md">{{ $t('liquidityManagement.event.saveEvent') }}</base-button>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { BFormInput } from 'bootstrap-vue'
import LoginService from '@/services/LoginService.js'
import { required, minLength, minValue, maxValue, maxLength } from 'vuelidate/lib/validators'
import Swal from 'sweetalert2'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  data () {
    return {
      config: {
        dateFormat: 'm-d-Y'
      },
      firstAttemp: false
    } 
  },
  components: {
    BFormInput,
    flatPickr
  },
  computed: {
    validDate () {
      return this.$store.getters.getDateStatus
    },
    validName () {
      return this.$store.getters.getNameStatus
    }
  },
  methods: {
    async saveEvent () {
      this.firstAttemp = true
      this.$v.$touch()

      if (!this.$v.$invalid) {
        let event = {
          '_id': this.liquidityEvent._id,
          'name': this.liquidityEvent.name,
          'startDate': new Date(this.liquidityEvent.startDate).toISOString(),
          'endDate': new Date(this.liquidityEvent.endDate).toISOString(),
          'reward': this.liquidityEvent.reward
        }

        await this.$store.dispatch('areDatesValid', event)
        await this.$store.dispatch('areNameValid', event)

        if (this.validName) {
          if(this.validDate) {
            try {
              Swal.fire({
                title: 'Please Wait',
                html: 'Updating Event',
                showConfirmButton: false,
                allowOutsideClick: false,
                onBeforeOpen: () => {
                  Swal.showLoading()
                }
              })

              await LoginService.updateLiquidityEvent(event)
              
              Swal.close()
              
              this.$emit('edit-event')
            } catch (error) {
              console.log(error)
              Swal.close()
              this.$alert(
                this.$t('liquidityManagement.event.serverError'),
                'Error',
                'error'
              )
            }
          } else {
            this.$alert(
              this.$t('liquidityManagement.event.rangeInvalid'),
              'Error',
              'error')
          }
        } else {
          this.$alert(
            this.$t('liquidityManagement.event.nameInvalid'),
            'Error',
            'error')
        }
      } else {
        this.$alert(
          this.$t('liquidityManagement.event.error'),
          'Warning',
          'warning')
      }
    },
    cancel () {
      this.$emit('edit-event')
    }
  },
  props: {
    liquidityEvent: Object
  },
  validations: {
    liquidityEvent: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50)
      },
      startDate: {
        required
      },
      endDate: {
        required
      },
      reward: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(1000000),
        maxLength: maxLength(7)
      }
    }
  }
}
</script>

<style>
.error {
  text-align: left !important;
  color: red;
  font-weight: 100;
  font-size: .8rem;
  padding-left: 1.2rem;
}

.date {
  background-color: #fff !important;
}
</style>